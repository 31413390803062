import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { Image, MagazineCard } from '@vgn-medien-holding/vgn-fe-components';
import { SocialLink } from '@components/atoms/Link/SocialLink';
import { Carousel } from '@components/molecules/Carousel/Carousel';
import AppContext from '@lib/AppContext';
import { Navigation } from '../../molecules/navigation/Navigation';

export const Footer = () => {
  const { ref: inViewRef, inView } = useInView({
    rootMargin: '50px 350px',
    triggerOnce: true,
  });

  const context = useContext(AppContext);
  const { footer, magazines } = context;
  const navigations = footer?.navigation?.items;

  return (
    <footer className="mt-40 bg-black p-4 lg:p-7">
      <div className="-mt-28 px-4 pb-28 text-center font-herokid text-sm font-medium text-black dark:text-white md:text-base lg:text-base">
        Alle Magazine der VGN Medien Holding
      </div>
      <div
        className="flex min-h-[700px] flex-col items-center rounded-2xl bg-gradient-to-r from-gray-700 to-gray-840 px-10 pb-15 text-white"
        ref={inViewRef}
      >
        <div className="relative mx-auto -mt-24 grid h-48 max-h-full w-60 rounded-2xl border-0.5 border-gray-650/65 bg-gray-700 bg-gradient-to-b from-white/0 via-white/0 to-black/20 p-4 text-center font-semibold drop-shadow-2xl xs:h-48 sm:h-51 sm:w-[380px] md:w-[619px]">
          {inView && (
            <Carousel
              classProps={{
                buttonLeft: '-left-10 md:left-0 md:relative md:mr-4',
                buttonRight: '-right-10 md:right-0 md:relative md:ml-4',
                root: 'max-w-md md:max-w-2xl overflow-hidden lg:max-w-screen-md h-full max-h-full min-h-0',
                carousel: 'w-full h-full pr-4',
                slidesWrapper: 'w-full gap-0',
                slide: 'pr-4',
              }}
            >
              {magazines?.map((magazine) => (
                <MagazineCard
                  key={magazine.id}
                  magazine={magazine}
                  width={102}
                  height={120}
                  classProps={{
                    root: 'h-fit w-fit transition-transform duration-300 pointer:hover:translate-y-0 translate-y-1',
                    text: 'text-xs absolute -bottom-6 inset-x-0',
                  }}
                />
              ))}
            </Carousel>
          )}
        </div>
        <div className="flex w-full flex-wrap justify-center gap-8 pt-10 md:justify-between">
          <Image
            src="/assets/logos/tvm/logo.png"
            width={124}
            height={30}
            copyright=""
            alt="Logo"
            classProps={{ container: 'w-auto' }}
          />
          <nav className="flex items-center gap-8">
            <SocialLink url="https://www.facebook.com/tvmediaAT" type="facebook" width={15} height={15} />
            <SocialLink url="https://www.youtube.com/user/TVMEDIAplayer" type="youtube" width={19} height={21} />
          </nav>
        </div>
        <div className="flex w-full flex-col pt-18 xl:grid xl:grid-cols-[32rem,1fr]">
          <div className="grid content-start items-start gap-6 pb-15 text-gray-400">
            <div className="font-herokid font-medium text-white xl:max-w-sm">{navigations?.[0]?.title}</div>
            <div className="lg:w-1/2 xl:max-w-sm">{navigations?.[0]?.children?.[0]?.title || ''}</div>
          </div>
          <div className={'flex flex-col justify-between gap-4 md:flex-row lg:basis-1/2'}>
            {navigations?.slice(1).map((nav, index) => (
              <Navigation
                classProps={{
                  root: ' pb-14',
                  heading: 'font-herokid font-medium',
                  list: 'pt-4 text-gray-400 font-semibold',
                  listItem: 'pb-2',
                  link: 'underline decoration-transparent hover:decoration-primary underline-offset-4 transition-colors duration-150',
                }}
                key={index}
                items={nav.children}
                withHeading={true}
                title={nav.title}
              >
                {navigations?.length - 2 == index && (
                  <>
                    {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                    <li className={'ot-sdk-show-settings cursor-pointer pb-2'}>Cookie Einstellungen</li>
                    <li className={'cursor-pointer'}>
                      <a href={'/sitemap-redirects'}>Redirect Sitemap</a>
                    </li>
                  </>
                )}
              </Navigation>
            ))}
          </div>
        </div>
        <div className="grid w-full md:grid-cols-2">
          <div className="flex flex-wrap gap-3 text-sm text-gray-400 md:col-start-2 md:row-start-2 md:justify-self-end">
            <a
              href="https://play.google.com/store/apps/details?id=at.tvmedia&gl=AT"
              target="_blank"
              rel="noopener noreferrer"
              title="Google Playstore"
            >
              <Image
                src="/assets/icons/icon-appstore-google.svg"
                alt="Google Play Store"
                width={128}
                height={40}
                copyright=""
                className="h-10 w-auto"
              />
            </a>
            <a
              href="https://apps.apple.com/at/app/tv-media-das-beste-tv-programm/id573531919"
              target="_blank"
              rel="noopener noreferrer"
              title="Apple Appstore"
            >
              <Image
                src="/assets/icons/icon-appstore-apple.svg"
                alt="Apple App Store"
                width={128}
                height={40}
                copyright=""
                className="h-10 w-auto"
              />
            </a>
          </div>
          <hr className="col-span-2 my-4 w-full border-gray-500/65 md:row-start-1" />
          <div className="text-sm text-gray-400">© 2024 TV-MEDIA. All rights reserved</div>
        </div>
      </div>
    </footer>
  );
};
