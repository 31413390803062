import React, { FC } from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';

const icons = {
  facebook: {
    src: '/assets/icons/icon-facebook.svg',
    alt: 'Facebook',
  },
  youtube: {
    src: '/assets/icons/icon-youtube.svg',
    alt: 'Youtube',
  },
};

interface LinkProps extends PropsWithClassProps {
  type: 'facebook' | 'youtube';
  url: string;
  width: number;
  height: number;
}

export const SocialLink: FC<LinkProps> = ({ type, classProps, url, width, height }) => {
  const icon = icons[type];

  return (
    <a
      className={`${classProps?.root || ''} flex cursor-pointer items-center hover:opacity-50`}
      target={'_blank'}
      aria-label={icon.alt}
      href={url || ''}
      rel="noreferrer"
    >
      <Image src={icon.src} alt={icon.alt} width={width} height={height} copyright="" placeholder="empty" />
    </a>
  );
};
